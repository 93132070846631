<template>
  <div class="bottom">
    <div class="mainbottom">
      <div class="item">
        <div class="title">DEEPSHIKHA TRADING CO PVT LTD </div>
        <div class="text">ShikhaFinHub, the exclusive lending app of DEEPSHIKHA TRADING CO PVT LTD, an RBI-certified
          NBFC</div>
      </div>
      <div class="item">
        <div class="title">Product</div>
        <div class="text">Personal Loan <br> <br>
          Medical Loan <br><br>
          Travel Loan
        </div>
      </div>
      <div class="item">
        <div class="title">Reach us</div>
        <div class="text">info@deepshikhatrading.com <br><br>Call us on +91 08586802451 <br><br> 9/1 R.N.MUKHARJEE
          ROAD.5TH FLOOR, KOLKATA WB 700001 IN</div>
      </div>
    </div>

    <div class="last">
      © 2024 DEEPSHIKHA TRADING CO PVT LTD. All rights reserved.
    </div>
  </div>
</template>

<script>
export default {
  name: "bottom",
};
</script>
<style scoped>
@media screen and (max-width: 1300px) {
  .bottom {
    display: none;
  }
}


.bottom {
  width: 100%;

  height: 317px;
  background: #070606;
}

.mainbottom {
  width: 1000px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;


  .item {
    width: 270px;
    padding-top: 46px;


    .title {
      width: 100%;
      width: 261px;
      height: 28px;
      line-height: 28px;
      font-family: PingFang SC;
      color: #ff7715;
      font-size: 20px;
    }

    .text {
      width: 100%;
      margin-top: 24px;
      line-height: 20px;
      font-family: PingFang SC;
      color: #ffffff;
      font-size: 14px;
    }
  }
}

.last {
  opacity: 40%;
  height: 20px;
  line-height: 20px;
  font-family: PingFang SC;
  color: #ffffff;
  font-size: 14px;
  /* margin: 0 auto; */
  margin-top: 44px;
  text-align: center;
}
</style>
