import { render, staticRenderFns } from "./MyBottom.vue?vue&type=template&id=5b9eb01b&scoped=true"
import script from "./MyBottom.vue?vue&type=script&lang=js"
export * from "./MyBottom.vue?vue&type=script&lang=js"
import style0 from "./MyBottom.vue?vue&type=style&index=0&id=5b9eb01b&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5b9eb01b",
  null
  
)

export default component.exports