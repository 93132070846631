import Vue from "vue";
import VueRouter from "vue-router";
import Index from "../Index/index.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "index",
    component: Index,
    redirect: "/",
    children: [
      {
        path: "/",
        name: "index",
        component: () => import("../views/Index.vue"),
      },
      {
        path: "/services",
        name: "services",
        component: () => import("../views/Services.vue"),
      },
      {
        path: "/aboutus",
        name: "aboutus",
        component: () => import("../views/Aboutus.vue"),
      },
      {
        path: "/policies",
        name: "policies",
        component: () => import("../views/Policies.vue"),
      },
      {
        path: "/contactinfo",
        name: "contactinfo",
        component: () => import("../views/Contactinfo.vue"),
      },

      {
        path: "/info",
        name: "info",
        component: () => import("../views/Tc.vue"),
      },
    ],
  },
];

const router = new VueRouter({
  mode: "history",
  scrollBehavior: () => ({ y: 0 }),
  routes,
});

export default router;
