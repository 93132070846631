<template>
  <div>
    <MyHeader />
    <router-view></router-view>
    <MyBottom />
  </div>
</template>

<script>
import MyBottom from "../components/MyBottom";
import MyHeader from "../components/MyHeader"
export default {
  name: "leroy",
  components: {
    MyHeader,
    MyBottom,
  },
};
</script>
